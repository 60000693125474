import * as React from 'react'
import * as css from './CategoryAndDate.module.css'
import { IoMdPricetags } from 'react-icons/io'
import { GoCalendar } from 'react-icons/go'
import { GrUpdate } from 'react-icons/gr'
import clsx from 'clsx'

const CategoryAndDate = props => {
  const {
    category,
    publishDate,
    formattedPublishDate,
    updateDate,
    formattedUpdateDate,
    className,
  } = props

  return (
    <div className={clsx(css.root, className)}>
      <span className="with-icon">
        <IoMdPricetags />
        {category.length
          ? category.map(({ title }) => title).join`, `
          : 'Uncategorized'
        }
      </span>

      {'/'}
      <time className="with-icon" dateTime={publishDate}>
        <GoCalendar />
        {formattedPublishDate}
      </time>

      {updateDate && <>
        {'/'}
        <time className="with-icon" dateTime={updateDate}>
          <GrUpdate />
          {formattedUpdateDate}
        </time>
      </>}
    </div>
  )
}

export default CategoryAndDate
