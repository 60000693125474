import * as React from 'react'
import * as css from './search.module.css'
import DefaultLayout from 'layouts/Default'
import { GlobalProvider, GlobalContext } from 'components/Context'
import PostPreview from 'components/common/PostPreview'

const searchBody = (body, query) => body.some(({ children }) =>
  children.some(({ text }) =>
    !!query && text.toLowerCase().includes(query.toLowerCase())
  )
)

const Results = ({ query }) => {
  const { allPosts } = React.useContext(GlobalContext)

  const results = allPosts.filter(({ title, body }) => (
    !!query && (title.toLowerCase().includes(query.toLowerCase()) || searchBody(body, query))
  ))

  return <>
    <h2 className={css.stats}>Found {results.length} result(s)</h2>

    {!!results.length && <ul className={css.list}>
      {results.map((post, key) => (
        <PostPreview post={post} query={query} key={key} />
      ))}
    </ul>}
  </>
}

const SearchPg = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const query = params.get`query`

  return (
    <DefaultLayout className="container side-padding section-padding">
      <GlobalProvider>
        <h1 className="h1">Search for "<i>{query}</i>"</h1>

        <Results query={query} />
      </GlobalProvider>
    </DefaultLayout>
  )
}

export default SearchPg
