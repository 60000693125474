import * as React from 'react'
import CategoryAndDate from './CategoryAndDate'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as css from './PostPreview.module.css'
import highlight from 'utils/highlight'

const PostPreview = ({ post, query, large }) => (
  <article className={css.root}>
    <Link className={css.link} to={`/${ post.seo.slug.current }`}>
      {post.featuredImg
        ? <GatsbyImage
          className={css.img}
          image={getImage(post.featuredImg.image.asset)}
          alt={post.featuredImg.alt || post.title}
        />
        : <div className={css.placeholder}></div>
      }

      <div
        className={`${css.title} ${large ? 'h1' : 'h3'}`}
        dangerouslySetInnerHTML={{ __html: highlight(post.title, query) }}
      />

      <CategoryAndDate {...post} />

      {large && <>
        <p className="h4">{post.seo.description}</p>

        <div className={css.read}>
          Read Article »
        </div>
      </>}
    </Link>
  </article>
)

export default PostPreview
